export const isPasswordValid = input => {
  //atleast 8 characters, 1 capital letter, 1 lower case letter,
  const regex = new RegExp(
    /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/
  )

  return regex.test(input)
}

export const isEmailValid = input => {
  const regex = new RegExp(/.+@.+\..+/)

  return regex.test(input)
}

export const isPhoneValid = input => {
  // corresponds to valid phone number. leading + allowed, 10-15 nunbers

  const regex = new RegExp(/^\+?[0-9]{10,15}$/)

  if (input === "") return false //ensure that input is not empty to prevent erros

  var inputNoSpaces = input.replace(/\s/g, "")
  return regex.test(inputNoSpaces)
}

export const isInsuranceCardValid = input => {
  // 20 numbers, nothing else allows

  const regex = new RegExp(/^[0-9]{20}$/)

  if (input === "") return false //ensure that input is not empty to prevent erros

  var inputNoSpaces = input.replace(/\s/g, "")
  return regex.test(inputNoSpaces)
}

export const isZipCodeValid = (input, country) => {
  // 20 numbers, nothing else allows

  const regex = new RegExp(/^[0-9]+$/)

  switch (country) {
    case "Schweiz":
      if (regex.test(input) && input.length === 4) {
        return true
      } else {
        return false
      }
    case "Deutschland":
      if (regex.test(input) && input.length === 5) {
        return true
      } else {
        return false
      }
    default:
      return false
  }
}


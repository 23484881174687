import React, { useState, useEffect } from "react"
import {
  TextField,
  Typography,
  Grid,
  ThemeProvider,
  makeStyles,
  Button,
} from "@material-ui/core"

import { withFB } from "../Firebase/firebasecontext"
import theme from "../Form/theme"
import "../globalStyles.css"
import { isEmailValid } from "../utils/validators"
import Paper from "@material-ui/core/Paper"
import logo from "../../images/DoryGo_logo.png"

const useStyles = makeStyles(theme => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  logo: {
    contentAlign: "center",
    width: "50%",
  },
  logoBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  formHeader: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
    },
  },
  buttons: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  errorMessage: {
    color: "#FF3333",
    fontSize: "1rem",
    fontWeight: 300,
    textAlign: "center",
  },
  SuccessMessage:{
    marginTop:"1rem"
  }
}))

/**
 *
 * @param {TypeProp} props
 * @returns {Object} React component
 */
function ResetPasswordBase(props) {
  const classes = useStyles()

  const [form, setForm] = useState({
    email: "",
  })
  const [isFormValid, setIsFormValid] = useState(false)
  const [stateErrorMessage, setStateErrorMessage] = useState({
    email: "",
  })

  const [resetSuccessfull, setResetSuccessfull] = useState(false)

  const onForgotPasswordClicked = () => {
    props.firebase
      .doPasswordReset(form.email)
      .catch(() => {
        console.log("A FB error occured")
      })
      setResetSuccessfull(true)

  }

  const handleChange = e => {
    //update form state
    setForm({ ...form, [e.target.name]: e.target.value })

    if (form.email === "") {
      setStateErrorMessage({
        ...stateErrorMessage,
        ["email"]: props.txt.errorValueNeeded,
      })
    } else if (!isEmailValid(form.email)) {
      setStateErrorMessage({
        ...stateErrorMessage,
        ["email"]: props.txt.errorEmail,
      })
    } else {
      setStateErrorMessage({ ...stateErrorMessage, ["email"]: "" })
      setIsFormValid(true)
    }
  }

  const validateInput = () => {
    // validate Input
    if (form.email === "") {
      setStateErrorMessage({
        ...stateErrorMessage,
        ["email"]: props.errors.errorValueNeeded,
      })
    } else if (!isEmailValid(form.email)) {
      setStateErrorMessage({
        ...stateErrorMessage,
        ["email"]: props.errors.errorEmail,
      })
    } else {
      setStateErrorMessage({ ...stateErrorMessage, ["email"]: "" })
    }
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className={classes.layout}>
          <Paper className={classes.paper}>
            <div className={classes.logoBox}>
              <a href="https://dorygo.com">
                <img src={logo} width="100%" className={classes.logo} />
              </a>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h3" className={classes.formHeader}>
                  {" "}
                  {props.txt.Header}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  type="email"
                  error={stateErrorMessage.email !== "" ? true : false}
                  variant="outlined"
                  name="email"
                  label={props.txt.labelEmail}
                  helperText={stateErrorMessage.email}
                  onChange={handleChange}
                  onBlur={() => validateInput("email")}
                  value={form.email}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <div className={classes.buttons}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onForgotPasswordClicked}
                    className={classes.button}
                    disabled={!isFormValid}
                  >
                    {props.txt.btnResetTxt}
                  </Button>
                  {resetSuccessfull && (
                    <div className={classes.SuccessMessage}>
                      <Typography variant="body1">
                        {props.txt.SuccessMessage}
                      </Typography>
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </ThemeProvider>
    </>
  )
}

const ResetPassword = withFB(ResetPasswordBase)
export default ResetPassword

import React from 'react'
import styled from "styled-components"
import LayoutOB from '../components/layoutob.js'
import { useLocation } from '@reach/router'
import ResetPassword from '../components/ResetPassword'
import { deDE } from "../lang/de-DE"
import { deCH } from "../lang/de-CH"

const Wrapper = styled.div`
display:flex;
justify-content:flex-start;
align-items:flex-start;
flex: 1 0 auto;
min-height: 80vh;
`
export default function PasswordResetPage() {

    const location = useLocation();
    var searchParams = new URLSearchParams(location.search);
    var countryParam = searchParams.get("country");
    let txt, errorsTxt, language
    if (countryParam==="DE"){
        txt         =   deDE.ResetPassword
        errorsTxt   =   deDE.Errors
        language    =   "de-DE"
    }else{
        txt         =   deCH.ResetPassword
        errorsTxt   =   deCH.Errors
        language    =   "de-CH"
    }


    return (
        <LayoutOB location={countryParam} language={language}>
        <Wrapper>
            <ResetPassword txt={txt} errors={errorsTxt}/>
            </Wrapper>
        </LayoutOB>
    )
}


//<FormPage/>